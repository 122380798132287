import { createStyles } from '@mui/styles';

export const SessionStyle = createStyles({
  root: {
    borderTop: '10px solid #F7F7F7',
    borderBottom: '10px solid #F7F7F7',
    padding: '16px 30px',
    backgroundColor: '#fff',
  },
  head: {
    width: '100%',
  },
  title: {
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
    lineHeight: '24px',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  subTitleCompleted: {
    fontSize: 12,
    padding: '4px 0px',
    lineHeight: '16px',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    color: '#558B2F',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  subTitleStarted: {
    fontSize: 12,
    padding: '4px 0px',
    lineHeight: '16px',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    color: '#039BE5',
  },
  buttonContainer: {
    maxWidth: '400px',
    display: 'flex',
    width: '400px',
    bottom: 0,
    padding: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    paddingRight: '40px',
  },
  message: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#4E5968',
  },
  subHeader: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    padding: '2px'
  },
  message2: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
  warning: {
    fontWeight: 600,
  }
});
