import { UserPreferences } from '../stores/types/userPreferences.interface';
import { API_KEYS, Api } from './base.api';

export const userPreferencesApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchUserPreferences: builder.query<UserPreferences, void>({
      query: () => ({
        url: `/core/v1/${API_KEYS.USERS}/me/preferences/personalization`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.USERS, id: 'USER_PREFERENCES' },
      ],
    }),
  }),
});

export const {
  useFetchUserPreferencesQuery,
  useLazyFetchUserPreferencesQuery,
} = userPreferencesApi;
