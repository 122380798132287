import React, { useContext } from 'react';
import { PaymentMode } from './types/payment-mode.enum';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import { useTranslation } from 'react-i18next';
import { PaymentContext } from '../../hooks/usePayment';
import PaymentCardForm from './payment-card-form.component';
import { deleteIcon } from '../../assets/icons/icons';

const ManagePayment = () => {
  const { i18n } = useTranslation();

  const paymentConsumer = useContext(PaymentContext);

  const { handlePaymentView, editMode, payments } = paymentConsumer;

  const onClickBack = () => {
    handlePaymentView(PaymentMode.NONE);
  };

  const showDeleteIcon = payments.payload?.length > 1;

  const onIconClicked = () => {
    handlePaymentView(PaymentMode.PAYMENT_DELETE);
  };

  return (
    <>
      <BackButtonHeader
        onBackArrowClicked={onClickBack}
        title={i18n.t(editMode ? 'edit_payment_method' : 'add_payment_method')}
        icon={editMode && showDeleteIcon && deleteIcon()}
        onIconClicked={editMode && onIconClicked}
      />
      <PaymentCardForm />
    </>
  );
};

export default ManagePayment;
