import { createApi } from '@reduxjs/toolkit/query/react';
import { dynamicBaseQuery } from './utils';

export enum API_KEYS {
  USER = 'user',
  CS_API = 'cs-api',
  INVOICES = 'invoices',
  CARD_ORDER = 'cardorders',
  RFID_API_KEY = 'cards',
  PAYMENT = 'payment-methods',
  CHARGERS = 'chargers',
  LOCATIONS = 'locations',
  SESSIONS = 'sessions',
  TRANSACTIONS = 'completedTransactions',
  USERS = 'users',
  NOTIFICATIONS = 'notifications',
  PLUG_AND_CHARGE_KEYS = '/internal/core/v2/chargelab-contracts'
}

export const Api = createApi({
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
  reducerPath: 'base',
  // keepUnusedDataFor: 10,
  // refetchOnMountOrArgChange: true,
}).enhanceEndpoints({
  addTagTypes: Object.values(API_KEYS),
});
