export const sortCountries = (country) => {
  const countryArray = [...country];

  const findCanadaIndex = countryArray.findIndex((ele) => ele.isoCode === 'CA');
  const CAObj = countryArray.splice(findCanadaIndex, 1);

  const findUSIndex = countryArray.findIndex((ele) => ele.isoCode === 'US');
  const USObj = countryArray.splice(findUSIndex, 1);
  return [...CAObj, ...USObj, ...countryArray];
};

export const elementFontOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700&display=swap',
    },
  ],
};
