import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
const styles = {
  listItem: {
    alignItems: 'flex-start',
    background: 'white',
  },
  listText: {
    marginLeft: -15,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  listItemIcon: {
    marginTop: '4px',
  },
};

interface IProps {
  classes: ClassNameMap;
  children: JSX.Element | JSX.Element[];
  isButton?: true;
  onClick?: Function;
  primary?: JSX.Element | JSX.Element[];
  text?: string;
}
const DetailsItem = ({
  isButton,
  children,
  classes,
  onClick,
  primary,
  text,
}: IProps) => {
  const clickHandler = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <ListItem
      button={isButton}
      onClick={clickHandler}
      className={classes.listItem}
    >
      <ListItemIcon className={classes.listItemIcon}>{children}</ListItemIcon>
      {primary ? (
        primary
      ) : (
        <ListItemText
          disableTypography
          className={classes.listText}
          primary={text}
        />
      )}
    </ListItem>
  );
};

export default withStyles(styles)(DetailsItem);
