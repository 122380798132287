import { Grid, Typography } from '@mui/material';
import { withStyles, ClassNameMap } from '@mui/styles';
import clsx from 'clsx';
import { Country, State } from 'country-state-city';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownIcon from '../../../assets/icons/receipt/Dropdown.svg';
import { useAuth } from '../../../hooks/useAuth';
import { UserPersonalInfo } from '../../../stores/types/user.types';
import CustomTextField from '../../react-native-material-wrapper/text-field.component';
import { BackButtonHeader } from '../../ui-components/back-button-header.component';
import { ViewWrapper } from '../../ui-components/view-wrapper.component';
import ActionButton from '../action-button.component';
import { userStyles } from '../styles';
import { UserMode } from '../types/user-mode.enum';

interface UserAddressProps {
  setView: Function;
  currentUser: UserPersonalInfo;
  classes: ClassNameMap;
}
const UserAddress = ({ classes, setView, currentUser }: UserAddressProps) => {
  const { i18n } = useTranslation();
  const [user, setUser] = useState(currentUser);
  const auth = useAuth();
  const [countryDropdownVisible, setCountryDropdownVisible] = useState(false);
  const [provinceDropdownVisible, setProvinceDropdownVisible] = useState(false);

  const onClickBack = () => {
    setView(UserMode.NONE);
  };

  const handleInputChange = (key: string, value: string) => {
    setUser({ ...user, address: { ...user.address, [key]: value } });
  };

  const onClickCountryDropdown = (ele) => {
    setCountryDropdownVisible(false);
    setUser({
      ...user,
      address: {
        ...user.address,
        country: ele.name,
        isoCode: ele.isoCode,
        province: '',
      },
    });
  };

  const countryDropdown = () => {
    const countries = Country.getAllCountries();
    return countries.map((ele) => (
      <div
        onClick={() => onClickCountryDropdown(ele)}
        className={clsx({
          [classes.dropdownMenuItem]: true,
          [classes.dropdownMenuItemHover]: true,
          [classes.dropDownSelectedItem]:
            user?.address?.isoCode === ele.isoCode,
        })}
        style={{ textAlign: 'left' }}
        key={ele.name}
      >
        {ele.name}
      </div>
    ));
  };

  const onClickProvinceDropdown = (ele) => {
    // This if helps add country to user object when user keeps default country option of dropdown
    if (!user?.address?.isoCode) {
      setUser({
        ...user,
        address: {
          ...user.address,
          country: 'Canada',
          isoCode: 'CA',
          province: ele.isoCode,
        },
      });
    } else {
      handleInputChange('province', ele.isoCode);
    }
    setProvinceDropdownVisible(false);
  };

  const provinceDropdown = () => {
    const province = State.getStatesOfCountry(user?.address?.isoCode || 'CA');
    return province.map((ele) => (
      <div
        onClick={() => onClickProvinceDropdown(ele)}
        className={clsx({
          [classes.dropdownMenuItem]: true,
          [classes.dropdownMenuItemHover]: true,
          [classes.dropDownSelectedItem]:
            user?.address?.province === ele.isoCode,
        })}
        style={{ textAlign: 'left' }}
        key={ele.isoCode}
      >
        {ele.isoCode}
      </div>
    ));
  };

  const handleCountryDropdown = () => {
    setCountryDropdownVisible(!countryDropdownVisible);
    setProvinceDropdownVisible(
      provinceDropdownVisible
        ? !provinceDropdownVisible
        : provinceDropdownVisible
    );
  };

  const handleProvinceDropdown = () => {
    setCountryDropdownVisible(
      countryDropdownVisible ? !countryDropdownVisible : countryDropdownVisible
    );
    setProvinceDropdownVisible(!provinceDropdownVisible);
  };

  const handleSaveValidation = () => {
    return (
      !user?.address?.addressLine1 ||
      !user?.address?.city ||
      !user?.address?.province ||
      !user?.address?.country ||
      !user?.address?.isoCode ||
      !user?.address?.postalCode
    );
  };

  const onSave = () => {
    auth.updateUserAddress(user);
    setView(UserMode.NONE);
  };

  return (
    <ViewWrapper>
      <BackButtonHeader
        title={i18n.t('address')}
        onBackArrowClicked={onClickBack}
      />
      <Grid container className={classes.formWrapper}>
        <Grid
          item
          xs={6}
          className={classes.paddingBottom8}
          style={{ paddingRight: '6px' }}
        >
          <Typography
            className={clsx(
              classes.labelM,
              classes.paddingTop,
              classes.paddingBottom8
            )}
          >
            {i18n.t('country')}
          </Typography>
          <button
            className={clsx(
              classes.drodDownButton,
              classes.paddingTop,
              classes.labelB2
            )}
            onClick={handleCountryDropdown}
          >
            <Grid
              container
              item
              alignItems='center'
              justifyContent='space-between'
            >
              <Grid item>
                {Country.getCountryByCode(user?.address?.isoCode || 'CA').name}
                {countryDropdownVisible && (
                  <div className={clsx(classes.dropDownMenu)}>
                    {countryDropdown()}
                  </div>
                )}
              </Grid>
              <Grid item alignItems='center'>
                <img
                  src={DropdownIcon}
                  alt=''
                  className={classes.displayBlock}
                />
              </Grid>
            </Grid>
          </button>
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.paddingBottom8}
          style={{ paddingLeft: '6px' }}
        >
          <Typography
            className={clsx(
              classes.labelM,
              classes.paddingTop,
              classes.paddingBottom8
            )}
          >
            {i18n.t('state_province')}
          </Typography>
          <button
            className={clsx(
              classes.drodDownButton,
              classes.paddingTop,
              classes.labelB2
            )}
            onClick={handleProvinceDropdown}
          >
            <Grid
              container
              item
              alignItems='center'
              justifyContent='space-between'
            >
              <Grid item>
                {user?.address?.province || (
                  <>
                    <Typography
                      className={classes.labelB2}
                      style={{ color: '#B0B8C1' }}
                    >
                      {i18n.t('state_province')}
                    </Typography>
                  </>
                )}
                {provinceDropdownVisible && (
                  <div className={clsx(classes.dropDownMenu)}>
                    {provinceDropdown()}
                  </div>
                )}
              </Grid>
              <Grid item>
                <img
                  src={DropdownIcon}
                  alt=''
                  className={classes.displayBlock}
                />
              </Grid>
            </Grid>
          </button>
        </Grid>
        <Grid
          item
          xs={12}
          className={clsx(classes.paddingBottom8, classes.paddingTop)}
        >
          <Typography className={clsx(classes.labelM, classes.paddingBottom8)}>
            {i18n.t('address')}
          </Typography>
          <CustomTextField
            className={clsx(classes.inputWrapper, classes.labelB2)}
            fullWidth
            value={user?.address?.addressLine1}
            onChange={(e) => handleInputChange('addressLine1', e.target.value)}
            variant='filled'
            required
            placeholder={i18n.t('address')}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid item xs={12} className={classes.paddingBottom8}>
          <CustomTextField
            className={clsx(classes.inputWrapper, classes.labelB2)}
            fullWidth
            value={user?.address?.addressLine2}
            onChange={(e) => handleInputChange('addressLine2', e.target.value)}
            variant='filled'
            required
            placeholder={i18n.t('address_unit_apt')}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.inputWrapper },
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingRight: '6px' }}>
          <Typography
            className={clsx(
              classes.labelM,
              classes.paddingTop,
              classes.paddingBottom8
            )}
          >
            {i18n.t('city')}
          </Typography>
          <CustomTextField
            className={clsx(classes.inputWrapper, classes.labelB2)}
            fullWidth
            value={user?.address?.city}
            onChange={(e) => handleInputChange('city', e.target.value)}
            variant='filled'
            required
            placeholder={i18n.t('city')}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: '6px' }}>
          <Typography
            className={clsx(
              classes.labelM,
              classes.paddingTop,
              classes.paddingBottom8
            )}
          >
            {i18n.t('zip_postal_code')}
          </Typography>
          <CustomTextField
            className={clsx(classes.inputWrapper, classes.labelB2)}
            fullWidth
            value={user?.address?.postalCode}
            onChange={(e) =>
              handleInputChange('postalCode', e.target.value.toUpperCase())
            }
            variant='filled'
            required
            placeholder={i18n.t('zip_postal_code')}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
      </Grid>
      <ActionButton
        label={i18n.t('save')}
        action={onSave}
        isDisabled={handleSaveValidation()}
      />
    </ViewWrapper>
  );
};

export default withStyles(userStyles)(UserAddress);
